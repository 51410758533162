import $ from 'jquery';
import 'what-input';
import  Cookies from 'js-cookie';
import AOS from 'aos';
import "magnific-popup";
import Swiper from 'swiper';
import Parallax from 'parallax-js'
import 'parsleyjs';
import 'jquery-match-height'
import 'jquery-countto'
import inView from "in-view";
import Plyr from 'plyr';
import select2 from 'select2/dist/js/select2.full.js'


// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
window.$ = $;
require('foundation-sites');


// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

let Page = Page || {};
window.Page = Page;

(function($) {
    
    /* ---------------------------------------------
        Global Variables
    --------------------------------------------- */
        
        Page.$window = $(window);
        Page.$html = $('html');
        Page.$body = $('body');
        Page.$document = $(document);
    
    /* ---------------------------------------------
        APP document Ready
    --------------------------------------------- */
    Page.documentOnReady = {
        init: function() {
            Page.initialize.init();
        }
    };
    
    /* ---------------------------------------------
        Page document Load
    --------------------------------------------- */
    Page.documentOnLoad = {
        init: function() {
            Page.initialize.preloader();
        }
    };
    
    /* ---------------------------------------------
        APP document Resize
    --------------------------------------------- */
    Page.documentOnResize = {
        init: function() {
            var t = setTimeout(function() {
                //
            }, 250);
        }
    };
    
    /* ---------------------------------------------
        Scripts initialization
    --------------------------------------------- */
    Page.$document.ready( Page.documentOnReady.init);
    Page.$window.on('load', Page.documentOnLoad.init);
    Page.$window.on('resize', Page.documentOnResize.init);
    
    /* ------------------------------------------------------------------------------------------------------------------
        #############################################################################################################
    ------------------------------------------------------------------------------------------------------------------ */

    Page.initialize = {
        init: function() {
            Page.initialize.foundation.init();
            Page.initialize.aos();
            Page.initialize.header();
            Page.initialize.formValidation();
            Page.initialize.sidebar();
            Page.initialize.select2();
            Page.initialize.customFile();
            Page.initialize.magnificPopup();
            Page.initialize.cookie();
            Page.initialize.innerLinks();
            Page.initialize.swiper();
            Page.initialize.equalizer();
            Page.initialize.videoTabs();
            Page.initialize.countTo();
            Page.initialize.calculator();
        },

        /* ---------------------------------------------
            Foundation
        --------------------------------------------- */
        foundation: {
            init: () => {
                Page.$document.foundation();
            },
            destroy: () => { }
        },
        
        /* ---------------------------------------------
            Preloader
        --------------------------------------------- */
        preloader: function() {
            Page.$body.removeClass("loading");
            Page.$body.addClass("loaded");
        },

        /* ---------------------------------------------
            Calculator
        --------------------------------------------- */
        calculator: function() {
            if ($('form#params').length > 0) {
                //wszystkie selekty przed zaĹadowaniem strony
                $.ajax({
                    type: 'GET',
                    url: 'https://romi-calc.hbz.pl/default/dataform',
                    async: true,
                    jsonpCallback: 'callbackSelects',
                    dataType: 'jsonp',
                    success: function() {
                        console.log("Pobrane select!");
                    },
                    error: function() {
                        console.log('failed select!');
                    }
                });


                

                window.callbackSelects = function( dSelect ){
                    if (dSelect) {
                        var s_ups = dSelect.ups;



                        $.each(s_ups, function(key, value) {
                            var data_str = 'data-box="' + escape(value.box) + '"' + ' data-power="' + value.output_power + '"' + 'data-typology ="' + value.typology + '"' + 'data-p_in="' + value.phase_in + '"' + 'data-p_out = "' + value.phase_in + '"' + 'data-ids = "' + value.ids + '"';

                            $('#battery_name').append('<option value="' + key + '" ' + data_str + '>' + value.model + ' </option>');
                        });
                        /*
                        $('#battery_name').each( function(){

                                $(this).html( $(this).find('option').sort(function(a, b) {
                                return a.text == b.text ? 0 : a.text < b.text ? -1 : 1
                                }) );						
                        });
                        */
                        $("#battery_name [value='0']").attr("selected", "selected");

                        var s_box = dSelect.box;
                        $.each(s_box, function(key, value) {
                            //console.log('Do html' + value.box + 'dlugoĹÄ' + value.box.length);					 
                            // $('#battery_box').append('<option value="' + $.text(value.box) +'">' + $.text(value.box) +' </option>');
                            $('<option>').val(value.box).text(value.box).appendTo('#battery_box');
                        });

                        var s_typ = dSelect.typology;
                        $.each(s_typ, function(key, value) {
                            $('<option>').val(value.typology).text(value.typology).appendTo('#battery_typology');
                        });
                    }
                }
                //koniec	

                // wybiera ups z prawej strony i jego parametry przerzuca do lewych inputĂłw
                $('#battery_name').change(function() {
                    var empt = $("#battery_name option:selected").val();

                    if (empt != 0) {
                        //alert(empt);
                        var f_box = $("#battery_name option:selected").data('box');

                        var f_power = $("#battery_name option:selected").data('power');
                        var f_ids = $("#battery_name option:selected").data('ids');
                        var f_typology = $("#battery_name option:selected").data('typology');
                        var f_p_in = $("#battery_name option:selected").data('p_in');
                        var f_p_out = $("#battery_name option:selected").data('p_out');
                        var f_p_denom = "Va";
                        //var f_min_time = "5";
                        //console.log("zepsute" + f_box);
                        $("#ids").val(f_ids);

                        //	$("#battery_time").val(f_min_time);

                        $("#ups_power").val(f_power);
                        $("#ups_power").prop('disabled', true);

                        $("#ups_power_denomination  option").each(function() {
                            if ($(this).val() == f_p_denom) {
                                $(this).attr("selected", "selected");
                            } else {
                                if ($(this).val() != f_p_denom)
                                    $(this).removeAttr("selected");
                            }
                        });
                        $("#ups_power_denomination").prop('disabled', true).addClass("dis");

                        $("#battery_typology  option").each(function() {
                            if ($(this).val() == f_typology) {
                                $(this).attr("selected", "selected");
                            } else {
                                if ($(this).val() != f_typology)
                                    $(this).removeAttr("selected");
                            }
                        });
                        $("#battery_typology").prop('disabled', true).addClass("dis");

                        $("#battery_box  option").each(function() {
                            if ($(this).text() == unescape(f_box)) {
                                $(this).attr("selected", "selected");
                            } else {
                                if ($(this).text() != unescape(f_box))
                                    $(this).removeAttr("selected");
                            }
                        });
                        //$("#battery_box").find('option[value="'+ f_box +'"]').prop('selected', 'selected');
                        $("#battery_box").prop('disabled', true).addClass("dis");

                        $("#battery_phase_in").find('option[value="' + f_p_in + '"]').prop('selected', 'selected');
                        $("#battery_phase_in").prop('disabled', true).addClass("dis");

                        $("#battery_phase_out").find('option[value="' + f_p_out + '"]').prop('selected', 'selected');
                        $("#battery_phase_out").prop('disabled', true).addClass("dis");
                    } else {
                        $("#ids").val(0);
                        $("#battery_box").prop('disabled', false).removeClass("dis");
                        $("#battery_phase_out").prop('disabled', false).removeClass("dis");
                        $("#battery_phase_in").prop('disabled', false).removeClass("dis");
                        $("#battery_typology").prop('disabled', false).removeClass("dis");
                        $("#ups_power_denomination").prop('disabled', false).removeClass("dis");
                        $("#ups_power").prop('disabled', false);
                    }

                });
                //koniec



                //zmiany w pierwszym inputie dezaktywujÄ dwa 

                // $( "#ups_power" ).keyup(function( event ) {
                // if(parseInt($("#ups_power").val()) != "0") {
                // $("#battery_load_power").prop('disabled', true);	
                // $("#additional_power").prop('disabled', true);
                // }
                // });


                // Funkcja sprawdzanie pĂłl
                // function eFunction() {
                // var i_val1 = "", i_val2 = "", i_val3 = "", i_val4 = "", moc_u = "", moc_o = "";

                // i_val1 = $("#ups_power").val();
                // i_val2 = $("#ups_power_denomination option:selected").text();
                // i_val3 = $("#battery_load_power").val();
                // i_val4 = $("#battery_load_power_denomination option:selected").text();

                // if(i_val2 == "W"){
                // moc_u = i_val1*1;
                // }
                // if(i_val2 == "Va"){
                // moc_u = i_val1*1;
                // }
                // if(i_val2 == "kW"){
                // moc_u = i_val1*1000;
                // }				
                // if(i_val2 == "kVa"){
                // moc_u = i_val1*1000;
                // }

                // if(i_val4 == "W"){
                // moc_o = i_val3*1;
                // }
                // if(i_val4 == "kW"){
                // moc_o = i_val3*1000;
                // }
                // if(i_val4 == "Va"){
                // moc_o = i_val3*1;
                // }
                // if(i_val4 == "kVa"){
                // moc_o = i_val3*1000;
                // }

                // if((moc_o > moc_u)&&(moc_u != 0)){
                // console.log(moc_u);
                // $( "#error-message" ).text("Obecnie Moc odbiorĂłw* jest wiÄksza od Mocy UPS!");
                // $( "#error-message" ).removeClass("hidden");
                // $( "#result-search" ).addClass("hidden");
                // return 1;
                // }else{

                // $( "#error-message" ).text();
                // $( "#error-message" ).addClass("hidden");
                // return 0;
                // }
                // }

                

                window.onSubmit = function( form ){
                    // if (eFunction() === 1)
                    // return false;

                    var form_data = JSON.stringify($(form).serializeArray());
                    if ($("#ids").val() == 0) {

                        $("#param-search").addClass("hidden");

                        //  <-----------
                        $("#param_img").removeClass("hidden");
                        //	$("#battery_load_power").prop('disabled', false);
                        //	$("#additional_power").prop('disabled', false);
                        $.ajax({
                            type: 'GET',
                            url: 'https://romi-calc.hbz.pl/default/forminput',
                            async: true,
                            // data: {send_data: form_data},
                            data: {
                                q: form_data
                            },
                            jsonpCallback: 'callback1',
                            dataType: 'jsonp',
                            success: function() {
                                //  console.log(data);
                                console.log("Pobrane filtrowane UPS!");
                                $("#param_img").addClass("hidden");
                            },
                            error: function() {
                                console.log('failed');
                            }
                        });

                    } else {
                        onSubmit1(form_data);
                    }

                    // $("#ids").val();
                    // $("#battery_box").prop('disabled', false).removeClass("dis");
                    // $("#battery_phase_out").prop('disabled', false).removeClass("dis");
                    // $("#battery_phase_in").prop('disabled', false).removeClass("dis");
                    // $("#battery_typology").prop('disabled', false).removeClass("dis");
                    // $("#ups_power_denomination").prop('disabled', false).removeClass("dis");
                    // $("#ups_power").prop('disabled', false);
                    return false;
                    //	}
                }

                // wyswietlanie w trzy kolumny
                window.callback1 = function( data ){
                    console.log('----')
                    console.log('callback1')
                    console.log(data)
                    console.log('----')
                    var txt = "";
                    var col1 = false;
                    var col2 = false;
                    var col3 = false;
                    //bĹÄd bez wyswietlania wyniku
                    if (data.error) {
                        $("#error-message").html(data.error);
                        $("#error-message").removeClass("hidden");
                        $("#result-search").addClass("hidden");
                    } else {
                        //bĹÄd z wyswietlaniem wyniku
                        if (data.parse_error) {
                            $("#error-message").html(data.parse_error);
                            $("#error-message").removeClass("hidden")
                        } else {
                            $("#error-message").text();
                            $("#error-message").addClass("hidden");
                        }


                        if (data) {

                            console.log(data)
                            var col1 = data["1"];
                            var col2 = data["5"];
                            var col3 = data["10"];
                            var col1_a, col1_b, col2_a, col2_b, col3_a, col3_b;
                            var empt = " ";

                            var defaultImg = '<img src="assets/round.png" alt="No image">';
                            var logo = '<img src="assets/logo_gt_top.png" alt="GT UPS">';
                            var pion = '<img src="assets/division.jpg" alt="UPS">';
                            var poziom = '<img src="assets/level.jpg" alt=" UPS">';
                            //console.log(col3.under.id_ups);



                            function colObj(box, model, weight, height, site, time, image, price, cab, cabspec, prod) {
                                this.box = box;
                                this.model = model;
                                this.weight = weight;
                                this.height = height;
                                this.site = site;
                                this.time = time;
                                this.image = image;
                                this.price = price;
                                this.cab = cab;
                                this.cabspec = cabspec;
                                this.prod = prod;

                                if ((this.model) && (this.image == "")) {
                                    this.image = defaultImg;
                                }
                                if ((this.model) && (this.image != " ")) {
                                    this.price = price + ' $';
                                }
                                if (this.site != " ") {
                                    this.site = '<a href="' + site + '" target="_blank">czytaj więcej...</a>';
                                } else {
                                    this.site = " ";
                                }
                                if (this.time != " ") {
                                    this.time = time + ' min.';
                                }
                                if (this.cab > 0) {
                                    this.cab = cab + ' X ' + cabspec;
                                } else {
                                    this.cab = " ";
                                }
                            }
                            if (col1.under || col1.over) {

                                var colTab1 = false;
                                if (col1.under && col1.over) {
                                    colTab1 = true;
                                    col1_a = new colObj(col1.over.box, col1.over.model, col1.over.weight, col1.over.height, col1.over.link, col1.over.time, col1.over.img, col1.over.totalPrice, col1.over.cabinet_count, col1.over.cabinet, logo);

                                    col1_b = new colObj(col1.under.box, col1.under.model, col1.under.weight, col1.under.height, col1.under.link, col1.under.time, col1.under.img, col1.under.totalPrice, col1.under.cabinet_count, col1.under.cabinet, logo);
                                } else {
                                    if (col1.over) {
                                        col1_a = new colObj(col1.over.box, col1.over.model, col1.over.weight, col1.over.height, col1.over.link, col1.over.time, col1.over.img, col1.over.totalPrice, col1.over.cabinet_count, col1.over.cabinet, logo);
                                        col1_b = new colObj(empt, empt, empt, empt, empt, empt, empt, empt, empt, empt, empt);
                                    } else {
                                        col1_a = new colObj(col1.under.box, col1.under.model, col1.under.weight, col1.under.height, col1.under.link, col1.under.time, col1.under.img, col1.under.totalPrice, col1.under.cabinet_count, col1.under.cabinet, logo);
                                        col1_b = new colObj(empt, empt, empt, empt, empt, empt, empt, empt, empt, empt, empt);
                                    }
                                }
                            } else {
                                col1_a = new colObj(empt, empt, empt, empt, empt, empt, empt, empt, empt, empt, empt);
                            }
                            //do przerobienia  ////////////////////////////////////////
                            if (col2.under || col2.over) {

                                var colTab2 = false;
                                if (col2.under && col2.over) {
                                    colTab2 = true;
                                    col2_a = new colObj(col2.over.box, col2.over.model, col2.over.weight, col2.over.height, col2.over.link, col2.over.time, col2.over.img, col2.over.totalPrice, col2.over.cabinet_count, col2.over.cabinet, logo);
                                    col2_b = new colObj(col2.under.box, col2.under.model, col2.under.weight, col2.under.height, col2.under.link, col2.under.time, col2.under.img, col2.under.totalPrice, col2.under.cabinet_count, col2.under.cabinet, logo);
                                } else {
                                    if (col2.over) {
                                        col2_a = new colObj(col2.over.box, col2.over.model, col2.over.weight, col2.over.height, col2.over.link, col2.over.time, col2.over.img, col2.over.totalPrice, col2.over.cabinet_count, col2.over.cabinet, logo);

                                        col2_b = new colObj(empt, empt, empt, empt, empt, empt, empt, empt, empt, empt, empt);
                                    } else {
                                        col2_a = new colObj(col2.under.box, col2.under.model, col2.under.weight, col2.under.height, col2.under.link, col2.under.time, col2.under.img, col2.under.totalPrice, col2.under.cabinet_count, col2.under.cabinet, logo);
                                        col2_b = new colObj(empt, empt, empt, empt, empt, empt, empt, empt, empt, empt, empt);
                                    }
                                }
                            } else {
                                col2_a = new colObj(empt, empt, empt, empt, empt, empt, empt, empt, empt, empt, empt);
                            }



                            if (col3.under || col3.over) {

                                var colTab3 = false;
                                if (col3.under && col3.over) {
                                    colTab3 = true;

                                    col3_a = new colObj(col3.over.box, col3.over.model, col3.over.weight, col3.over.height, col3.over.link, col3.over.time, col3.over.img, col3.over.totalPrice, col3.over.cabinet_count, col3.over.cabinet, logo);
                                    col3_b = new colObj(col3.under.box, col3.under.model, col3.under.weight, col3.under.height, col3.under.link, col3.under.time, col3.under.img, col3.under.totalPrice, col3.under.cabinet_count, col3.under.cabinet, logo);
                                } else {
                                    if (col3.over) {
                                        col3_a = new colObj(col3.over.box, col3.over.model, col3.over.weight, col3.over.height, col3.over.link, col3.over.time, col3.over.img, col3.over.totalPrice, col3.over.cabinet_count, col3.over.cabinet, logo);
                                        col3_b = new colObj(empt, empt, empt, empt, empt, empt, empt, empt, empt, empt, empt);
                                    } else {
                                        col3_a = new colObj(col3.under.box, col3.under.model, col3.under.weight, col3.under.height, col3.under.link, col3.under.time, col3.under.img, col3.under.totalPrice, col3.under.cabinet_count, col3.under.cabinet, logo);
                                        col3_b = new colObj(empt, empt, empt, empt, empt, empt, empt, empt, empt, empt, empt);
                                    }
                                }
                            } else {
                                col3_a = new colObj(empt, empt, empt, empt, empt, empt, empt, empt, empt, empt, empt);
                            }


                            if (col1_a.model != " " || col2_a.model != " " || col3_a.model != " ") {

                                $("#result-search").removeClass("hidden");

                                if (colTab1) {
                                    $("#c1").empty();
                                    $("#c1").append('<div id="tab1_1" class="tab1 tab-active">' + col1_a.model + '</div>');
                                    $("#c1").append('<div id="tab1_2" class="tab2 tab-alt">' + col1_b.model + '</div>');
                                } else {
                                    if (col1_a.model != " ") {
                                        $("#c1").empty();
                                        $("#c1").append('<div id="tab1_1" class="tab1 tab-active">' + col1_a.model + '</div>');
                                    } else {
                                        $("#c1").empty();
                                    }

                                }
                                if (colTab2) {
                                    $("#c2").empty();
                                    $("#c2").append('<div id="tab2_1" class="tab1 tab-active">' + col2_a.model + '</div>');
                                    $("#c2").append('<div id="tab2_2" class="tab2 tab-alt">' + col2_b.model + '</div>');
                                } else {
                                    if (col2_a.model != " ") {
                                        $("#c2").empty();
                                        $("#c2").append('<div id="tab2_1" class="tab1 tab-active">' + col2_a.model + '</div>');
                                    } else if (col2_a.model !== " ") {
                                        $("#c2").empty();
                                        $("#c2").append('<div id="tab2_1" class="tab1 tab-active">' + col2_a.model + '</div>');
                                        console.log("aaaaaaaaaaaaaaa");

                                    } else {
                                        $("#c2").empty();

                                    }

                                }
                                if (colTab3) {
                                    $("#c3").empty();
                                    $("#c3").append('<div id="tab3_1" class="tab1 tab-active">' + col3_a.model + '</div>');
                                    $("#c3").append('<div id="tab3_2" class="tab2 tab-alt">' + col3_b.model + '</div>');
                                } else {
                                    if (col3_a.model != " ") {
                                        $("#c3").empty();
                                        $("#c3").append('<div class="tab1 tab-active">' + col3_a.model + '</div>');
                                    } else {
                                        $("#c3").empty();
                                    }
                                }

                                $("#prod1").html(col1_a.prod);
                                $("#prod2").html(col2_a.prod);
                                $("#prod3").html(col3_a.prod);
                                $("#mod1").text(col1_a.model + ' ' + col1_a.box);
                                $("#mod2").text(col2_a.model + ' ' + col2_a.box);
                                $("#mod3").text(col3_a.model + ' ' + col3_a.box);
                                $("#weight1").text(col1_a.weight);
                                $("#weight2").text(col2_a.weight);
                                $("#weight3").text(col3_a.weight);
                                $("#height1").text(col1_a.height);
                                $("#height2").text(col2_a.height);
                                $("#height3").text(col3_a.height);


                                $("#cab1").html(col1_a.cab);
                                $("#cab2").html(col2_a.cab);
                                $("#cab3").html(col3_a.cab);

                                $("#time1").text(col1_a.time);
                                $("#time2").text(col2_a.time);
                                $("#time3").text(col3_a.time);

                                $("#site1").html(col1_a.site);
                                $("#site2").html(col2_a.site);
                                $("#site3").html(col3_a.site);
                                $("#img1").html(col1_a.image);
                                $("#img2").html(col2_a.image);
                                $("#img3").html(col3_a.image);
                                $("#price1").text(col1_a.price);
                                $("#price2").text(col2_a.price);
                                $("#price3").text(col3_a.price);


                                if (colTab1) {
                                    $("#tab1_1").click(function() {
                                        if ($(this).hasClass("tab-alt")) {
                                            $(this).removeClass("tab-alt");
                                            $(this).addClass("tab-active");
                                            $("#tab1_2").removeClass("tab-active");
                                            $("#tab1_2").addClass("tab-alt");

                                            $("#prod1").html(col1_a.prod);
                                            $("#mod1").text(col1_a.model + ' ' + col1_a.box);
                                            $("#weight1").text(col1_a.weight);
                                            $("#height1").text(col1_a.height);
                                            $("#cab1").html(col1_a.cab);
                                            $("#time1").text(col1_a.time);
                                            $("#site1").html(col1_a.site);
                                            $("#img1").html(col1_a.image);
                                            $("#price1").text(col1_a.price);
                                        }


                                    });

                                    $("#tab1_2").click(function() {
                                        if ($(this).hasClass("tab-alt")) {
                                            $(this).removeClass("tab-alt");
                                            $(this).addClass("tab-active");
                                            $("#tab1_1").removeClass("tab-active");
                                            $("#tab1_1").addClass("tab-alt");


                                        }
                                        $("#prod1").html(col1_b.prod);
                                        $("#mod1").text(col1_b.model + ' ' + col1_b.box);
                                        $("#weight1").text(col1_b.weight);
                                        $("#height1").text(col1_b.height);
                                        $("#cab1").html(col1_b.cab);
                                        $("#time1").text(col1_b.time);
                                        $("#site1").html(col1_b.site);
                                        $("#img1").html(col1_b.image);
                                        $("#price1").text(col1_b.price);
                                    });


                                }


                                if (colTab2) {
                                    $("#tab2_1").click(function() {
                                        if ($(this).hasClass("tab-alt")) {
                                            $(this).removeClass("tab-alt");
                                            $(this).addClass("tab-active");
                                            $("#tab2_2").removeClass("tab-active");
                                            $("#tab2_2").addClass("tab-alt");


                                        }

                                        $("#prod2").html(col2_a.prod);
                                        $("#mod2").text(col2_a.model + ' ' + col2_a.box);
                                        $("#weight2").text(col2_a.weight);
                                        $("#height2").text(col2_a.height);
                                        $("#cab2").html(col2_a.cab);
                                        $("#time2").text(col2_a.time);
                                        $("#site2").html(col2_a.site);
                                        $("#img2").html(col2_a.image);
                                        $("#price2").text(col2_a.price);

                                    });

                                    $("#tab2_2").click(function() {
                                        if ($(this).hasClass("tab-alt")) {
                                            $(this).removeClass("tab-alt");
                                            $(this).addClass("tab-active");
                                            $("#tab2_1").removeClass("tab-active");
                                            $("#tab2_1").addClass("tab-alt");


                                        }
                                        $("#prod2").html(col2_b.prod);
                                        $("#mod2").text(col2_b.model + ' ' + col2_b.box);
                                        $("#weight2").text(col2_b.weight);
                                        $("#height2").text(col2_b.height);
                                        $("#cab2").html(col2_b.cab);
                                        $("#time2").text(col2_b.time);
                                        $("#site2").html(col2_b.site);
                                        $("#img2").html(col2_b.image);
                                        $("#price2").text(col2_b.price);


                                    });
                                }

                                if (colTab3) {
                                    $("#tab3_1").click(function() {
                                        if ($(this).hasClass("tab-alt")) {
                                            $(this).removeClass("tab-alt");
                                            $(this).addClass("tab-active");
                                            $("#tab3_2").removeClass("tab-active");
                                            $("#tab3_2").addClass("tab-alt");

                                        }
                                        $("#prod3").html(col3_a.prod);
                                        $("#mod3").text(col3_a.model + ' ' + col3_a.box);
                                        $("#weight3").text(col3_a.weight);
                                        $("#height3").text(col3_a.height);
                                        $("#cab3").html(col3_a.cab);
                                        $("#time3").text(col3_a.time);
                                        $("#site3").html(col3_a.site);
                                        $("#img3").html(col3_a.image);
                                        $("#price3").text(col3_a.price);
                                    });

                                    $("#tab3_2").click(function() {
                                        if ($(this).hasClass("tab-alt")) {
                                            $(this).removeClass("tab-alt");
                                            $(this).addClass("tab-active");
                                            $("#tab3_1").removeClass("tab-active");
                                            $("#tab3_1").addClass("tab-alt");



                                        }

                                        $("#prod3").html(col3_b.prod);
                                        $("#mod3").text(col3_b.model + ' ' + col3_b.box);
                                        $("#weight3").text(col3_b.weight);
                                        $("#height3").text(col3_b.height);
                                        $("#cab3").html(col3_b.cab);
                                        $("#time3").text(col3_b.time);
                                        $("#site3").html(col3_b.site);
                                        $("#img3").html(col3_b.image);
                                        $("#price3").text(col3_b.price);
                                    });
                                }
                            }


                        }

                    }


                } //koniec

                // drukowanie do jednej kolumny
                window.onSubmit1 = function( s ){
                    console.log(s + ' n,j,ghkjhg');
                    var form_data = s;


                    $.ajax({
                        type: 'GET',
                        url: 'https://romi-calc.hbz.pl/default/findups',
                        async: true,
                        //data: {send_data: form_data},
                        data: {
                            q: form_data
                        },
                        jsonpCallback: 'callback2',
                        dataType: 'jsonp',
                        success: function() {
                            //console.log(data1);
                            console.log("Pobrane filtrowane 2 UPS!");
                        },
                        error: function() {
                            console.log('failed  2');
                        }
                    });
                    return false;
                }

                window.callback2 = function( data ){
                    console.log('----')
                    console.log('callback2')
                    console.log(data)
                    console.log('----')
                    if (data.error) {
                        $("#error-message").text(data.error);
                        $("#error-message").removeClass("hidden");
                        $("#param-search").addClass("hidden");
                    } else {
                        $("#error-message").text();
                        $("#error-message").addClass("hidden");

                        $("#param-search").removeClass("hidden");
                        $("#result-search").addClass("hidden");

                        if (data['parse_error'] != "undefined") {
                            $("#error-message").html(data['parse_error']);
                            $("#error-message").removeClass("hidden")
                        } else {
                            $("#error-message").text();
                            $("#error-message").addClass("hidden");
                        }
                        if (typeof data['over'] == "undefined") {

                            if (data['parse_error']) {
                                $("#error-message").html(data['parse_error'] + "<br>" + "To jest maksymalna konfiguracja danego UPS'a");
                                $("#error-message").removeClass("hidden");
                            } else {
                                $("#error-message").html("To jest maksymalna konfiguracja danego UPS'a");
                                $("#error-message").removeClass("hidden");
                            }
                            var logo1 = '<img src="assets/logo_gt_top.png" alt="GT UPS">';

                            var pups = data['under'];
                            console.log(pups);
                            console.log('under');

                            $("#tab1_5").text(pups.model);

                            $("#prod5").html(logo1);
                            $("#mod5").text(pups.model);
                            $("#weight5").text(pups.weight);
                            $("#height5").text(pups.height);
                            if (pups.cabinet_count > 0) pups.cabinet = pups.cabinet_count + ' X ' + pups.cabinet;
                            $("#cab5").html(pups.cabinet);
                            if (pups.time) pups.time = pups.time + ' min.';
                            $("#time5").text(pups.time);
                            $("#site5").html('<a href="' + pups.link + '" target="_blank">czytaj więcej...</a>');
                            $("#img5").html(pups.img);

                            $("#price5").text(pups.totalPrice + ' $');
                        } else {
                            var logo1 = '<img src="assets/logo_gt_top.png" alt="GT UPS">';

                            var pups = data['over'];
                            console.log(pups);
                            console.log('over');

                            $("#tab1_5").text(pups.model);

                            $("#prod5").html(logo1);
                            $("#mod5").text(pups.model);
                            $("#weight5").text(pups.weight);
                            $("#height5").text(pups.height);
                            if (pups.cabinet_count > 0) pups.cabinet = pups.cabinet_count + ' X ' + pups.cabinet;
                            $("#cab5").html(pups.cabinet);
                            if (pups.time) pups.time = pups.time + ' min.';
                            $("#time5").text(pups.time);
                            $("#site5").html('<a href="' + pups.link + '" target="_blank">czytaj więcej...</a>');
                            $("#img5").html(pups.img);

                            $("#price5").text(pups.totalPrice + ' $');
                        }
                    }
                }
            }

        },

        /* ---------------------------------------------
            Product
        --------------------------------------------- */
        equalizer: function() {
            if ( $('.product-equalizer').length > 0 ){
                $('.product-equalizer .product-image').matchHeight({
                    byRow: false,
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.product-equalizer .product-title').matchHeight({
                    byRow: false,
                    property: 'height',
                    target: null,
                    remove: false
                });
            }

            if ( $('.product-list-box').length > 0 ){
                $('.product-list-box .box-image').matchHeight({
                    byRow: false,
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.product-list-box .box-content').matchHeight({
                    byRow: false,
                    property: 'height',
                    target: null,
                    remove: false
                });
            }
        },

        /* ---------------------------------------------
            Product
        --------------------------------------------- */
        videoTabs: function() {
            if ( $('#advice-tabs').length > 0 ){
                let players = [];
                $('.js-player').each(function() {
                    var player = new Plyr($(this));
                    players.push(player)
                });
                $('#advice-tabs').on('change.zf.tabs', function() {
                    $.each(players, function(index, player) {
                        player.stop();
                    });
                });
            }
        },

        /* ---------------------------------------------
            Cookie
        --------------------------------------------- */
        cookie: function () {
            let $cookieDiv = $("#cookie");
            let cookie_delay = 3000;
            if ( !Cookies.get('cookie-notyfication') ) {
                setTimeout(function () {
                    $cookieDiv.addClass("show");
                }, cookie_delay);
                $('#cookie-close').on('click', function () {
                    Cookies.set('cookie-notyfication', 'accept', { expires: 365 });
                    $cookieDiv.removeClass("show");
                });
            } 
        },

        /* ---------------------------------------------
            Preloader
        --------------------------------------------- */
        customFile: function() {
            $('.custom-file').each(function(){
                let custoFile = $(this);
                let $inputElement = $(this).find('input[type="file"]');
                let $inputElementText = $(this).find('.input-file-label');
                let $inputTextDefault = $inputElementText.innerHTML;
                let droppedFile;


                $(this).on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                });

                $(this).on('drop', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    droppedFile = e.originalEvent.dataTransfer.files;
                    $inputElement.trigger('change');
                });

                $inputElement.on("change", function() {
                    const fileList = droppedFile || this.files; 
                    if (fileList.length > 1){
                        $inputElementText.text('');
                        for (let file = 0; file < fileList.length; file++) {
                            $inputElementText.prepend( '<p>' + fileList[file].name +'</p>' );
                        }
                    } else {
                        if ( fileList.length ){
                            $inputElementText.text( fileList[0].name)
                        } else {
                            $inputElementText.text($inputTextDefault);
                        }
                    }
                });
            });
        },
        
        /* ---------------------------------------------
            Aos
        --------------------------------------------- */
        aos: function() {
            document.addEventListener('aos:in', function( aosElem ) {
                // aos in
            });
              
            document.addEventListener('aos:out', function( aosElem ) {
                // aos out
            });
            AOS.init({
                // Global settings
                disable: $(window).width() < 1199, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
                startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
                initClassName: 'aos-init', // class applied after initialization
                animatedClassName: 'aos-animate', // class applied on animation
                useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
              
                // Settings that can be overriden on per-element basis, by `data-aos-*` attributes:
                offset: 250, // offset (in px) from the original trigger point
                delay: 500, // values from 0 to 3000, with step 50ms
                duration: 400, // values from 0 to 3000, with step 50ms
                easing: 'ease', // default easing for AOS animations
                once: true, // whether animation should happen only once - while scrolling down
                mirror: false, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
            });
        },

        /* ---------------------------------------------
            boostrapSelect
        --------------------------------------------- */
        select2: function() {
            $('select:not(.phpdebugbar-datasets-switcher)').select2({
                minimumResultsForSearch: -1, 
                width: '100%',
            }, true);
            
            // select filter
            $('select[data-filter-for]').on('change', function() {
                var filterFor = $(this).attr('data-filter-for');
                var val = $(this).val();
                $('#' + filterFor).find('div[data-filter]').removeClass('active');
                $('#' + filterFor).find('div[data-filter="' + val + '"]').addClass('active');
                $(this).blur();
            });
            
            //select load content
            $('select[data-load]').each(function(){
                var loadIn = $(this).attr('data-load-in');
                var load = $(this).attr('data-load');
                $("#" + loadIn).load( load );
            });
            
            $('select[data-load]').on('change', function() {
                var loadIn = $(this).attr('data-load-in');
                var val = $(this).val();
                $("#" + loadIn).load( val );
            });
        },
        
        /* ---------------------------------------------
            Inner links
        --------------------------------------------- */
        innerLinks: function(){
            if($('.inner-link').length){
                $('.inner-link').on('click', function (e) {
                    e.preventDefault();
                    var href = $(this).attr('href');
                    var speed = parseInt( $(this).attr('data-speed'), 10) || 900;
                    var offset = $(this).attr('data-offset') || 0;
                    $('html, body').animate({
                        scrollTop: $(href).offset().top - offset
                    }, speed);
                    return false;
                });
            }
        },

        /* ---------------------------------------------
            Header, main menu
        --------------------------------------------- */
        header: function () {
            let $offcanvasMenu= $('#offcanvas');
            let $toggleMenu = $("#toggle-menu");
            let $header = $('.header');
            let scroll_amount = 50;
            let $megamenu = $('#megamenu');
            let $offcanvasSearch = $('#offcanva-search');
            let $toggleSearch = $("#toggle-search");
            let $closeSearch = $('#close-search')
            
            //menu
            $toggleMenu.click(function(){
                $(this).toggleClass("is-active");
                $offcanvasMenu.toggleClass('active');
                Page.$body.toggleClass("menu-is-active");
            });

            // scroll event
            if ( Page.$window.scrollTop() > scroll_amount ){
                Page.$body.addClass("body-scroll");
            }
            Page.$window.scroll(function() {
                if ( $(this).scrollTop() > scroll_amount ){
                    Page.$body.addClass("page-scroll");
                } else{
                    Page.$body.removeClass("page-scroll");
                }
            });

            // megamenu
            $('#trigger-megamenu').on('mouseenter', function(event) {
                $megamenu.addClass('show')
            });

            $('.menu-megamenu li[data-menu-category]').on('mouseenter', function(event) {
                let category = $(this).data('menu-category');
                if ($('.megamenu-category[data-category="' + category +'"]')) {
                    $('.megamenu-category-container').find('.megamenu-category').css('display', 'none');
                    $('.megamenu-category-container').find('.megamenu-category[data-category="' + category +'"]').css('display', 'block');
                }
            });
              
            $('.megamenu-inner').on('mouseleave', function(event) {
                $('.megamenu-category-container').find('.megamenu-category').css('display', 'none');
            });


            $('#megamenu').on('mouseleave', function(event) {
                $megamenu.removeClass('show')
            });

            //search
            $toggleSearch.click(function(){
                $offcanvasSearch.addClass('active');
                $('body').addClass("search-is-active");
                $offcanvasSearch.find('input:first').focus();
            });

            $closeSearch.click(function(){
                $offcanvasSearch.removeClass('active');
                $('body').removeClass("search-is-active");
            });

            $(document).on('keyup',function(evt) {
                if (evt.keyCode == 27) {
                    $('body').removeClass("search-is-active");
                    $offcanvasSearch.removeClass("active");
                }
            });
        },

        /* ---------------------------------------------
            Header
        --------------------------------------------- */
        sidebar: function() {
            let $sidebar= $('#sidebar');
            let $sidebaTrigger = $('#sidebar-trigger');
            let $sidebarClose = $('#sidebar-close');
            
            $sidebaTrigger.on('click', function(){
                $sidebar.addClass('open');
                Page.$body.addClass('sidebar-is-open');
            });
            $sidebarClose.on('click', function(){
                $sidebar.removeClass('open');
                Page.$body.removeClass('sidebar-is-open');
            });
        },

        /* ---------------------------------------------
            CountTo
        --------------------------------------------- */
        countTo: function() {
            if ( $('.count').length > 0 ){
                inView('.count').once('enter', function(){
                    setTimeout(function(){
                        $('.count').countTo();
                    },800);
                });
            }
        },
        
        /* ---------------------------------------------
            Form validation
        --------------------------------------------- */
        formValidation: function () {
            //Global validation
            $("form[novalidate] select:required").change(function() {
                $(this).parsley().validate();
            }); 
            
            window.Parsley.on('field:error', function() {
                this.$element.addClass('form-control-danger');
                if(this.$element.is('[type="file"]')) {
                   this.$element.closest('.custom-file').addClass('form-control-danger');
                }
            });
                
            window.Parsley.on('field:success', function() {
                this.$element.removeClass('form-control-danger');
                this.$element.addClass('form-control-success');
                if(this.$element.is('[type="file"]')) {
                    this.$element.closest('.custom-file').removeClass('form-control-danger');
                 }
            });

            // Form gruop heleper classes
            $('form[novalidate]').parsley({
                successClass: "has-success",
                errorClass: "has-danger",
                classHandler: function(el) {
                    return el.$element.closest(".form-group");
                },
                errorsWrapper: '<ul class="parslay-danger"></ul>',
                errorElem: '<li></li>'
            });

            $('form[novalidate]').on('submit', function( evt){
                var form = $(this);
                form.parsley().validate();
                if (form.parsley().isValid()){
                    if ( form.attr('data-prevent') === 'true' ){
                        evt.preventDefault();
                    }
                    if ( form.attr('data-modal-show')){
                        var modal = form.attr('data-modal-show');
                        form[0].reset();
                        $(modal + "").modal('show')
                    }
                }
            });


            // bind parsley to the form. Exclude all disabled fields
            $('#form-checkout').parsley({
                excluded: 'input[type=button], input[type=submit], input[type=reset], input[type=hidden], :disabled'
            });
            
            let $differentAddress = $('#differentAddress');
            $differentAddress.on('change', function() {
                if ( $(this).prop('checked') ) {
                    $('.different-address-container').find('input,select,radio').prop('disabled', false);
                } else {
                    $('.different-address-container').find('input,select,radio').prop('disabled', true);
                    $('.different-address-container').find('input,select,radio, .form-group').removeClass('has-danger form-control-danger');
                    $('#form-checkout').parsley().reset();
                }
            });
        },
        
        /* ---------------------------------------------
            Swiper
        --------------------------------------------- */
        swiper: function(){
            //swiper single
            if ( $('.swiper-single-container').length > 0 ) {
                $('.swiper-single-container').each(function(){
                    let $swiper = $(this).find('.swiper-container')
                    let swiperLength = $swiper.find('.swiper-slide').length;
                    let swiper = new Swiper($swiper, {
                        loop: swiperLength > 1,
                        autoplay: {
                            delay: 7000,
                            disableOnInteraction: false
                        },
                        effect: 'fade',
                        fadeEffect: {
                            crossFade: true
                        },
                        
                        pagination: {
                            el: $(this).find('.swiper-pagination'),
                            clickable: true,
                            type: 'bullets',
                            renderBullet: function (index, className) {
                                return '<span class="' + className + '">' + '0' + (index + 1) + '</span>';
                            },
                        },
                        speed: 3000,
                        simulateTouch: false,
                        touchRatio: 0,
                        allowTouchMove: false,
                        on: {
                            slideChange: function(){}
                        },
                    });
                });

                if ( $('#home-swiper').length > 0 ) {
                    let scene = document.getElementById('home-swiper');
                    let parallax = new Parallax(scene, {
                        selector: '.swiper-slide-bg',
                    });
                }
            }

            //swiper partners
            if ( $('#swiper-partners').length > 0 ) {
                let $swiper = $('#swiper-partners').find('.swiper-container');
                let swiperLength = $swiper.find('.swiper-slide').length;
                let swiper = new Swiper($swiper, {
                    loop: swiperLength > 6,
                    navigation: {
                        nextEl: $('#swiper-partners').find('.swiper-button-next'),
                        prevEl: $('#swiper-partners').find('.swiper-button-prev'),
                    },
                    slidesPerView: 6,
                    observer: true,
                    observeParents: true,
                    breakpointsInverse: false,
                    simulateTouch: false,
                    touchRatio: 0,
                    allowTouchMove: false,
                    breakpoints: {
                        1200: {
                            slidesPerView: 4,
                            loop: swiperLength > 4,
                        },
                        768: {
                            slidesPerView: 2,
                            loop: swiperLength > 2,
                        },
                    }
                });
            }
            
            // swiper products
            if ( $('.swiper-products-container').length > 0 ) {
                $('.swiper-products-container').each(function(){
                    let $swiper = $(this).find('.swiper-container')
                    let swiperLength = $swiper.find('.swiper-slide').length;
                    let swiper = new Swiper($swiper, {
                        loop: swiperLength > 4,
                        slidesPerView: 4,
                        spaceBetween: 20,
                        observer: true,
                        observeParents: true,
                        simulateTouch: false,
                        touchRatio: 0,
                        allowTouchMove: false,
                        navigation: {
                            nextEl: $(this).find('.swiper-button-next'),
                            prevEl: $(this).find('.swiper-button-prev'),
                        },
                        breakpoints: {
                            1200: {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            768: {
                                slidesPerView: 1,
                            },
                        }
                    });
                });
            }

            if ($('#swiper-product').length > 0){
                let $swiper = $('#swiper-product');
                let swiper = new Swiper($swiper, {
                    loop: false,
                    slidesPerView: 1,
                    observer: true,
                    observeParents: true,
                    simulateTouch: false,
                    touchRatio: 0,
                    allowTouchMove: false,
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                });

                $("#swiper-product-thumb").on('click', 'li', function(){
                    swiper.slideTo($(this).index(), 500);
                });
            }
        },

        /* ---------------------------------------------
            magnificPopup
        --------------------------------------------- */
        magnificPopup: function() {
            let $mfpGallery = $('.mfp-gallery');
            if ($mfpGallery.length) {
                $mfpGallery.each(function() { // the containers for all your galleries
                    $(this).magnificPopup({
                        delegate: 'a:not(.disable-mfp)', // the selector for gallery item
                        type: 'image',
                        //mainClass: 'mfp-with-zoom', // this class is for CSS animation below
                        mainClass: 'mfp-zoom-in',
                        showCloseBtn: true,
                        closeBtnInside: true,
                        closeOnContentClick: true,
                        closeMarkup: '<span class="mfp-close"><span class="icon-cross-out"></span></span>',
                        gallery: {
                            enabled: true,
                            arrowMarkup: '<span title="%title%" class="mfp-arrow mfp-arrow-%dir%"></span>', // markup of an arrow button
                        },
                        image: {
                            // titleSrc: function(item) {
                            //     return item.el.find('.overlay-content').html();
                            // }
                        },
                        removalDelay: 300, //delay removal by X to allow out-animation
                        callbacks: {
                            open: function() {
                                //overwrite default prev + next function. Add timeout for css3 crossfade animation
                                $.magnificPopup.instance.next = function() {
                                    var self = this;
                                    self.wrap.removeClass('mfp-image-loaded');
                                    setTimeout(function() { $.magnificPopup.proto.next.call(self); }, 120);
                                }
                                $.magnificPopup.instance.prev = function() {
                                    var self = this;
                                    self.wrap.removeClass('mfp-image-loaded');
                                    setTimeout(function() { $.magnificPopup.proto.prev.call(self); }, 120);
                                }
                            },
                            imageLoadComplete: function() {
                                var self = this;
                                setTimeout(function() { self.wrap.addClass('mfp-image-loaded'); }, 16);
                            }
                        }
                    });
                });
            };
            if ( $('.popup-video').length > 0 ){
                $('.popup-video').magnificPopup({
                    disableOn: 700,
                    type: 'iframe',
                    mainClass: 'mfp-fade',
                    removalDelay: 160,
                    preloader: false,
                    fixedContentPos: false
                });
            }

            if ( $('[data-lightbox="inline"]').length > 0 ){
                $('[data-lightbox="inline"]').magnificPopup({
                    type:'inline',
                });
            }
        },
    };
})(jQuery);
